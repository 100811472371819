import React from 'react';
// import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
// import matter from 'gray-matter';
// import { GatsbyLinkButton } from '@talves/gatsby-theme-components-tui';
import { Box, useThemeUI, useColorMode } from '../../provider';

function setupColors(state, action) {
  const textColor =
    (action.theme && action.theme.colors && action.theme.colors.text) ||
    'black';
  const bgColor =
    (action.theme && action.theme.colors && action.theme.colors.background) ||
    'white';
  const borderColor =
    (action.theme && action.theme.colors && action.theme.colors.muted) ||
    'white';
  switch (action.colorMode) {
    case 'dark':
      return {
        textColor,
        bgColor,
        borderColor,
      };
    default:
      return {
        textColor,
        bgColor,
        borderColor,
      };
  }
}

const PostsListItem = ({ data, body }) => {
  const { theme } = useThemeUI();
  const [colorMode] = useColorMode();
  const [state, dispatch] = React.useReducer(setupColors, {});

  React.useEffect(() => {
    dispatch({ theme, colorMode });
  }, [theme, colorMode]);

  return (
    <Box
      sx={{
        position: 'relative',
        // margin: '1rem',
        color: state.textColor,
      }}
    >
      <Box
        sx={{
          display: 'block',
          textDecoration: 'none',
          borderStyle: 'none',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            padding: '0.2rem 0.4rem 0.4rem',
            borderBottom: `1px solid ${theme.colors.primary}`,
            backgroundColor: 'rgba(0, 0, 0, 0.07)',
          }}
        >
          <Box
            sx={{
              color: 'secondary',
            }}
          >
            <Box>
              <Box
                as="span"
                sx={{
                  fontSize: [2],
                  fontWeight: 700,
                  textTransform: 'uppercase',
                }}
              >
                {data.title}
              </Box>
              {', '}
              <Box as="span" sx={{ fontSize: [1, 2], fontWeight: 500 }}>
                {data.location}
              </Box>
              <Box
                sx={{
                  fontSize: [1, 2],
                  float: 'right',
                  fontWeight: 600,
                  mr: 5,
                }}
              >
                {new Date(data.startdate).getFullYear()}-
                {data.enddate === null
                  ? 'Present'
                  : `${new Date(data.enddate).getFullYear()}`}
              </Box>
            </Box>
          </Box>
          {data.description && (
            <Box sx={{ fontSize: 1, fontWeight: 500, mb: 1 }}>
              {data.description}
            </Box>
          )}

          <Box
            sx={{
              fontSize: [1],
              pl: 4,
            }}
          >
            <Box
              sx={{
                fontSize: 2,
                fontWeight: 500,
                mb: 1,
              }}
            >
              <Box
                as="span"
                sx={{ borderBottom: `2px solid ${theme.colors.primary}` }}
              >
                {data.position}
              </Box>
            </Box>
            {data.summary && (
              <Box
                sx={{
                  fontWeight: 500,
                  mb: 2,
                }}
              >
                <Box as="span">{data.summary}</Box>
              </Box>
            )}
            <MDXRenderer>{body}</MDXRenderer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PostsListItem;
