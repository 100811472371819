import React from 'react';

import { Box, useThemeUI } from '../../provider';

import Information from './information';
import Experience from './experience';

const Resume = ({ children, data }) => {
  const { theme } = useThemeUI();
  return (
    <>
      <Information data={data.information}>
        <Experience data={data.experience} />
      </Information>
      {false && (
        <Box
          as="pre"
          sx={{ borderBottom: `3px solid ${theme.colors.primary}` }}
        >
          JSON.stringify(data, null, 2)
        </Box>
      )}
      <Box as="hr" sx={{ borderBottom: `1px solid ${theme.colors.primary}` }} />
    </>
  );
};

export default Resume;
