import React from 'react';
import { Box, useFeatures } from '../../provider';
import ExperienceCollection from './experience-list';

const Experience = ({ children, data }) => {
  const { heading, toTitleUpperCase } = useFeatures();
  const title = toTitleUpperCase('Professional Experience', 3, 2);
  return (
    <>
      <Box as="h2" sx={heading}>
        {title}
      </Box>
      <ExperienceCollection data={data} />
      {children}
    </>
  );
};

export default Experience;
