import React from 'react';
import { Global } from '@emotion/core';
import { Helmet } from 'react-helmet';
import { SEO } from '@talves/gatsby-theme-components-seo';
import { Box, useThemeUI, useColorMode } from '../../provider';
import {
  FlexLayout,
  Header,
  Main,
  Container,
  Footer,
} from '../layout/wrappers';
import ThemeSwitcher from '../ThemeSwitcher';

const Layout = ({
  children,
  header = 'Resume',
  footer = 'Copyright © 2020',
  frontmatter = {},
  ...props
}) => {
  const { theme } = useThemeUI();
  const [colorMode] = useColorMode();

  return (
    <FlexLayout variant="styles.root">
      <Helmet>
        <html lang="en" />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <title>{header}</title>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#3c6a84" />
        <meta name="theme-color" content="#3c6a84"></meta>
      </Helmet>
      <Global
        styles={{
          html: {
            boxSizing: 'border-box',
          },
          '*, *::before, *::after': {
            boxSizing: 'inherit',
            margin: 0,
            padding: 0,
          },
          body: {
            margin: 0,
            padding: 0,
          },
          '@media print': {
            'footer, header, .no-print': {
              display: 'none !important',
              height: 0,
            },
            '.no-print, .no-print *': {
              display: 'none !important',
              height: 0,
            },
          },
        }}
      />
      <SEO pageMeta={{ title: header, ...frontmatter }} />
      <Header
        className="no-print"
        sx={{
          padding: [2, 3, 4],
        }}
        variant="styles.Nav"
      >
        <Box as="h2" sx={{ ml: 10, color: theme.colors.navtext }}>
          {header}
        </Box>
        <Box sx={{ position: 'absolute', right: '10px' }}>
          <ThemeSwitcher displayMode={false} />
        </Box>
      </Header>
      <Main sx={{ ...props.backgroundStyles }} variant="styles.Layout">
        <Container>{children}</Container>
      </Main>
      <Footer
        className="no-print"
        sx={{
          height: 420,
          // filter: 'brightness(0.5) sepia(0.1) hue-rotate(20deg) saturate(5)',
          background: `url(/images/bottom-waves${
            colorMode === 'dark' ? '-dark' : ''
          }.svg), ${theme.colors.background}`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPositionX: 'center',
          backgroundPositionY: 'bottom',
        }}
      >
        {footer}
      </Footer>
    </FlexLayout>
  );
};

export default Layout;
