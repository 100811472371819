import React from 'react';
import { Box, useThemeUI } from '../../provider';
import ExperienceListItem from './experience-list-item';

const Flex = ({ children }) => <Box>{children}</Box>;

const ExperienceList = ({ data }) => {
  const context = useThemeUI();

  return (
    <Flex>
      {data &&
        data
          .filter(
            t => t.frontmatter.type && t.frontmatter.type === 'experience',
          )
          .sort((a, b) => {
            return Date.parse(a.frontmatter.startdate) <
              Date.parse(b.frontmatter.startdate)
              ? 1
              : -1;
          })
          .map((item, key) => {
            const dataItem = item.frontmatter;
            return (
              <ExperienceListItem
                key={key}
                data={dataItem}
                body={item.parent.body}
              />
            );
          })}
      {false && (
        <Box>
          <Box as="pre">{JSON.stringify(data, null, 2)}</Box>
          <Box as="pre">{JSON.stringify(context, null, 2)}</Box>
        </Box>
      )}
    </Flex>
  );
};

export default ExperienceList;
