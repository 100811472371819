import React from 'react';
import { useThemeUI, Box } from '@talves/gatsby-theme-site-provider';
export * from '@talves/gatsby-theme-site-provider';

const TitleUpperCase = ({ first, rest, size }) => {
  if (!rest) {
    return (
      <Box
        as="span"
        sx={{ fontSize: size.firstSize, textTransform: 'uppercase' }}
      >
        {`${first} `}
      </Box>
    );
  } else {
    return (
      <Box
        as="span"
        sx={{ fontSize: size.firstSize, textTransform: 'uppercase' }}
      >
        {first}
        <Box
          as="span"
          sx={{ fontSize: size.restSize, textTransform: 'uppercase' }}
        >
          {`${rest} `}
        </Box>
      </Box>
    );
  }
};

const getFeatures = theme => {
  return {
    heading: {
      borderBottom: `2px solid ${theme.colors.primary}`,
      mb: 2,
      fontStyle: 'italic',
    },
    toTitleUpperCase: (str, firstSize, restSize) => {
      const elements = str.split(' ').map(txt => {
        return React.createElement(TitleUpperCase, {
          first: txt.charAt(0),
          rest: txt.substr(1),
          size: { firstSize, restSize },
        });
      });
      return elements;
    },
  };
};

const useFeatures = () => {
  const { theme } = useThemeUI();
  const [features, setfeatures] = React.useState(getFeatures(theme));

  React.useEffect(() => {
    setfeatures(getFeatures(theme));
  }, [theme]);

  return features;
};

export { useFeatures };
