import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from './index';
import Resume from './resume';

export const fragment = graphql`
  fragment ExperienceCollection on Experience {
    name
    slug
    frontmatter {
      date
      startdate
      enddate
      exclude
      location
      position
      title
      type
      date
      summary
      description
    }
    parent {
      ... on Mdx {
        body
      }
    }
    rawBody
  }
`;

export default function withData(Component) {
  const WithDataComponent = props => {
    return (
      <StaticQuery
        query={graphql`
          query {
            allExperience {
              nodes {
                ...ExperienceCollection
              }
            }
            resumeInformation {
              avatar
              coreCompetencies
              cvpdf
              email
              phone
              location
              name
              summary
              technologies
              education {
                date
                exclude
                discipline
                school
              }
              extravolunteer {
                date
                exclude
                enddate
                location
                organization
                position
                summary
              }
              highlights {
                description
                exclude
                priority
              }
            }
          }
        `}
        render={data => (
          <Component
            data={{
              information: data.resumeInformation,
              experience: data.allExperience.nodes,
            }}
            {...props}
          />
        )}
      />
    );
  };
  return WithDataComponent;
}

export const ResumeWithData = withData(({ data, ...props }) => (
  <Layout {...props}>
    <Resume data={data} />
  </Layout>
));
