import React from 'react';

import { Box, Flex, useThemeUI, useFeatures } from '../../provider';

const InfoHeader = ({ data }) => {
  const { theme } = useThemeUI();
  return (
    <Flex
      sx={{
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          fontSize: [3, 4, 5],
          fontWeight: [400, 500, 600],
          flexBasis: '100%',
          boxSizing: 'border-box',
          borderBottom: `5px solid ${theme.colors.primary}`,
        }}
      >
        {data.name}
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          mb: 1,
          fontSize: [1, 2],
          flexBasis: '100%',
          boxSizing: 'border-box',
        }}
      >{`${data.location} • ${data.phone} • ${data.email}`}</Box>
    </Flex>
  );
};

const CareerProfile = ({ children, data }) => {
  const { heading, toTitleUpperCase } = useFeatures();
  const title = toTitleUpperCase('Career Profile', 3, 2);
  return (
    <Box>
      <Box as="h3" sx={heading}>
        {title}
      </Box>
      <Box sx={{ mb: 1, fontSize: 1 }}>{data.summary}</Box>
      {children}
      <Box sx={{ mb: 1, fontSize: 1, ml: 4 }}>
        <Box as="span" sx={{ fontWeight: 700 }}>
          Core Competencies:{' '}
        </Box>
        {data.coreCompetencies.join(', ')}
      </Box>
      <Box sx={{ mb: 1, fontSize: 1, ml: 4 }}>
        <Box as="span" sx={{ fontWeight: 700 }}>
          Technologies:{' '}
        </Box>
        {data.technologies.join(', ')}
      </Box>
    </Box>
  );
};

const CareerHighlights = ({ children, data = {} }) => {
  const { heading, toTitleUpperCase } = useFeatures();
  const title = toTitleUpperCase('Career Highlights', 3, 2);
  return (
    <Box>
      <Box as="h3" sx={heading}>
        {title}
      </Box>
      <Box sx={{ ml: 3, fontSize: 1 }}>
        <Box as="ul">
          {data.highlights
            .filter(t => !t.exclude)
            .sort((a, b) => a.priority > b.priority)
            .map((item, key) => (
              <Box key={key} as="li">
                {item.description}
              </Box>
            ))}
        </Box>
      </Box>
      {children}
    </Box>
  );
};

const Education = ({ children, data = {} }) => {
  const { heading, toTitleUpperCase } = useFeatures();
  const title = toTitleUpperCase('Education', 3, 2);
  return (
    <Box>
      <Box as="h3" sx={heading}>
        {title}
      </Box>
      <Box sx={{ ml: 4 }}>
        <Box as="div">
          {data.education
            .filter(t => !t.exclude)
            .sort((a, b) => (Date.parse(a.date) > Date.parse(b.date) ? 1 : -1))
            .map((item, key) => {
              let gradYear = Date.parse(item.date)
                ? new Date(Date.parse(item.date)).getFullYear()
                : 0;
              console.log(gradYear, new Date().getFullYear() - gradYear);
              if (gradYear !== 0 || new Date().getFullYear() - gradYear > 10) {
                gradYear = 0;
              }
              return (
                <Box key={key}>
                  <Box>
                    <Box
                      as="span"
                      sx={{ textTransform: 'uppercase', fontWeight: 700 }}
                    >
                      {item.school}
                    </Box>
                    <Box as="span" sx={{}}>
                      {gradYear ? `, ${gradYear}` : ''}
                    </Box>
                  </Box>
                  <Box sx={{ pl: 4, fontSize: 1 }}>{item.discipline}</Box>
                </Box>
              );
            })}
        </Box>
      </Box>
      {children}
    </Box>
  );
};

const ExtraVolunteer = ({ children, data }) => {
  const { heading, toTitleUpperCase } = useFeatures();
  const title = toTitleUpperCase(
    'EXTRACURRICULAR & VOLUNTEER ACTIVITIES',
    3,
    2,
  );
  return (
    <Box>
      <Box as="h3" sx={heading}>
        {title}
      </Box>
      <Box sx={{ ml: 4 }}>
        <Box as="div">
          {data.extravolunteer
            .filter(t => !t.exclude)
            .sort((a, b) => (Date.parse(a.date) < Date.parse(b.date) ? 1 : -1))
            .map((item, key) => (
              <Box key={key}>
                <Box>
                  <Box
                    as="span"
                    sx={{ textTransform: 'uppercase', fontWeight: 700 }}
                  >
                    {item.organization}{' '}
                  </Box>
                  <Box as="span" sx={{}}>
                    {item.location}
                    {', '}
                  </Box>
                  <Box as="span">
                    {new Date(item.date).getFullYear()}-
                    {item.enddate === null
                      ? 'Present'
                      : `${new Date(item.enddate).getFullYear()}`}
                  </Box>
                </Box>
                <Box sx={{ pl: 4, fontSize: 1 }}>
                  <Box as="span" sx={{ fontWeight: 700 }}>
                    {item.position}
                    {' - '}
                  </Box>
                  <Box as="span" sx={{}}>
                    {item.summary}
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
      {children}
    </Box>
  );
};

const Information = ({ children, data }) => {
  return (
    <Box>
      <InfoHeader data={data} />
      <CareerProfile data={data} />
      <CareerHighlights data={data} />
      {children}
      <Education data={data} />
      <ExtraVolunteer data={data} />
    </Box>
  );
};

export default Information;
